<template>
  <div class="indentDetails">
    <logistics :detailsData="detailsData"></logistics>
    <receiving-info :detailsData="detailsData"></receiving-info>
    <supplier :oederProductList="oederProductList"></supplier>
    <indent-date :detailsData="detailsData"></indent-date>
    <pay-bottom :detailsData="detailsData"></pay-bottom>
  </div>
</template>
<script>
import Logistics from "./components/Logistics";
import ReceivingInfo from "./components/ReceivingInfo";
import Supplier from "./components/Supplier";
import IndentDate from "./components/IndentDate";
import PayBottom from "./components/PayBottom";

export default {
  name: "IndentDetails",
  components: {
    Logistics,
    ReceivingInfo,
    Supplier,
    IndentDate,
    PayBottom
  },
  data() {
    return {
      orderNumber: 0,
      detailsData: {},
      oederProductList: null //订单列表
    };
  },
  beforeMount() {
    const { OrderNumber } = this.$route.query;
    this.orderNumber = OrderNumber;
  },
  mounted() {
    //获取数据
    this.loadOrderDetails();
  },
  methods: {
    //获取数据
    loadOrderDetails() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {
        OrderNumber: this.orderNumber
      };
      this.$api.order
        .loadOrderDetails(param)
        .then(res => {
          console.log(res);
          const data = res.data;
          this.detailsData = res.data;
          this.getSupplierList(res.data.OederProductList);
          console.log(data);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.isfirst = false;
          //console.log(error);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
      //console.log("这是isfirst" + this.isfirst);
    },
    //格式化商品列表返回值
    getSupplierList(oederProductList) {
      let newList = [];
      console.log(oederProductList);
      let filterOederProductList = oederProductList;
      oederProductList.forEach(item => {
        //newList=push(item)
        let list = filterOederProductList.filter(newItem => {
          return item.CustomerId == newItem.CustomerId;
        });
        let filterList = filterOederProductList.filter(newItem => {
          return item.CustomerId != newItem.CustomerId;
        });
        list.length !== 0 && newList.push(list);
        filterOederProductList = filterList;
      });
      this.oederProductList = newList;
    }
  }
};
</script>
<style lang="scss" scoped>
.indentDetails {
  width: 100%;
  // padding-bottom: 20px;
}
</style>
