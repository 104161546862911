<template>
  <div class="indentDate" v-if="detailsData">
    <van-row type="flex" align="center" class="infoBox">
      <van-col class="infoTitle">{{ $t.orderNumber }}</van-col>
      <van-col class="infoName">{{ detailsData.OrderNumber }}</van-col>
      <van-col class="infoCopy" @click.stop="onCopy(detailsData.OrderNumber)">{{
        $t.copy
      }}</van-col>
    </van-row>
    <van-row
      type="flex"
      align="center"
      class="infoBox newInfoBox"
      v-if="detailsData.PlaceOrderTime"
    >
      <van-col class="infoTitle">{{ $t.orderTime }}</van-col>
      <van-col class="infoNum">{{ detailsData.PlaceOrderTime }}</van-col>
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
import VueClipboard from "vue-clipboard2";
Vue.use(Row).use(Col).use(VueClipboard);

export default {
  name: "IndentDate",
  props: {
    detailsData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    onCopy(text) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.clickCopy, {
        text: text,
        alertText: this.$t.copySuccess,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.indentDate {
  width: 92%;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 14px;
  padding: 20px 0;
  background-color: #ffffff;
  @include publicBoxRadio;
  .infoBox {
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0 30px 0 30px;
    .infoTitle {
      width: 30%;
      color: #a7a7a7;
    }
    .infoName {
      width: 50%;
      @include spanNowrap;
      color: #333333;
    }
    .infoNum {
      @include spanNowrap;
      width: 50%;
      color: #333333;
    }
    .infoCopy {
      color: #43cd9a;
      width: 20%;
      text-align: right;
    }
  }
  .newInfoBox {
    margin-top: 24px;
  }
}
</style>
