<template>
  <div class="logistics" v-if="detailsData">
    <div class="title" v-if="detailsData.State">
      {{ detailsData.State | expressState }}
    </div>
    <van-row type="flex" align="center" class="infoBox">
      <van-col span="4" class="logo">
        <img
          class="logoIcon"
          src="~assets/img/store/icon_detail_logistics.png"
        />
      </van-col>
      <van-col span="16" class="info">{{
        detailsData.NewOrderLogistics
      }}</van-col>
      <!-- <van-col span="4" class="go">
        <img class="goIcon" src="~assets/img/store/icon_detail_right.png" />
      </van-col> -->
    </van-row>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "Logistics",
  props: {
    detailsData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.logistics {
  width: 100%;
  .title {
    background-color: #ffffff;
    padding: 15px 30px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #43cd9a;
    font-weight: bold;
    border-bottom: 1px solid #f6f6f6;
  }
  .infoBox {
    padding: 15px 0px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #ffffff;
    .logo {
      @include publicFlex;
      .logoIcon {
        width: 25px;
        height: 25px;
        margin: auto;
      }
    }
    .info {
      font-size: 12px;
      color: #000000;
    }
    .go {
      @include publicFlex;
      .goIcon {
        width: 15px;
        height: 15px;
        margin: auto;
      }
    }
  }
}
</style>
