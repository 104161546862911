<template>
  <div class="supplier" v-if="oederProductList">
    <!-- 渲染供应商 -->
    <div class="box" v-for="(item, index) in oederProductList" :key="index">
      <van-row class="titleInfo" @click="getDetailsInfo(item[0].CustomerId)">
        <van-col span="6" class="titleInfoName">{{ $t.supplier }}</van-col>
        <van-col span="14" class="titleInfoP">{{
          item[0].CompanyName
        }}</van-col>
        <van-col span="4" class="titleInfoBack">
          <img
            class="titleInfoBackIcon"
            src="~assets/img/store/icon_detail_right.png"
          />
        </van-col>
      </van-row>
      <!-- 渲染独立供应商商品 -->
      <div v-for="(newItem, newIndex) in item" :key="newIndex">
        <cargo-list :item="item"></cargo-list>
        <!-- 货物信息 -->
        <van-row
          type="flex"
          align="center"
          justify="space-between"
          class="payInfo"
        >
          <!-- <van-col class="payInfoLeft">
          <p class="payInfoTitle">{{ $t.productTotalPrice }}</p>
          <p class="payInfoTitle">{{ $t.freight }}</p>
          <p class="payInfoName newBottom">{{ $t.actualPayment }}</p>
        </van-col>
        <van-col class="payInfoRight">
          <p class="payInfoName">$9.99</p>
          <p class="payInfoName">$1.2</p>
          <p class="payInfoTotal newBottom">
            {{ $t.total }}：<span class="payInfoTotalUnit">$</span
            ><span class="payInfoTotalNum">9.99</span>
          </p>
        </van-col> -->
          <van-col class="payInfoLeft">
            <p class="payInfoName newBottom">{{ $t.totalAmount }}</p>
          </van-col>
          <van-col class="payInfoRight">
            <p class="payInfoTotal newBottom">
              <span class="payInfoTotalUnit">$</span
              ><span class="payInfoTotalNum">{{
                (newItem.PayProductPrice * newItem.Number) | formatPrice
              }}</span>
            </p>
          </van-col>
        </van-row>
      </div>
      <!-- 联系供应商-联系买家 -->
      <!-- <van-row
        type="flex"
        align="center"
        justify="space-between"
        class="touchBox"
      >
        <van-col class="touchItme">
          <button class="touchItmeBt">{{ $t.contactTheShop }}</button>
        </van-col>
        <van-col class="touchItme">
          <button class="touchItmeBt">{{ $t.contactSeller }}</button>
        </van-col>
      </van-row> -->
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
import CargoList from "@components/common/CargoList";

export default {
  name: "Supplier",
  props: {
    oederProductList: {
      type: Array,
    },
  },
  components: {
    CargoList,
  },
  data() {
    return {};
  },
  methods: {
    //跳转供应商
    getDetailsInfo(customerId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "drawableInfo",
        name: "DrawableInfo",
        query: [
          {
            name: "CustomerId",
            value: customerId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicSpan {
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding-top: 30px;
}
.supplier {
  width: 100%;
  .box {
    margin: 0 auto;
    width: 92%;
    margin-top: 15px;
    background-color: #ffffff;
    @include publicBoxRadio;
    padding: 0 20px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #000000;
    ::v-deep .cargoitem {
      padding: 30px 0;
    }
    .titleInfo {
      padding-top: 30px;
      .titleInfoName {
        font-size: 14px;
        margin: auto;
        line-height: 1;
      }
      .titleInfoP {
        font-size: 14px;
        @include spanNowrap;
        color: #000000;
        margin: auto;
        line-height: 1;
      }
      .titleInfoBack {
        text-align: center;
        .titleInfoBackIcon {
          width: 15px;
          height: 15px;
        }
      }
    }
    .payInfo {
      .payInfoLeft {
        .payInfoTitle {
          color: #a7a7a7;
          @include publicSpan;
        }
        .payInfoName {
          color: #000000;
          @include publicSpan;
        }
      }
      .payInfoRight {
        text-align: right;
        .payInfoTitle {
          color: #a7a7a7;
          @include publicSpan;
        }
        .payInfoName {
          color: #000000;
          @include publicSpan;
        }
        .payInfoTotal {
          @include publicSpan;
          .payInfoTotalUnit {
            font-size: 16px;
            color: #ff5852;
            font-weight: bold;
            margin-right: 6px;
          }
          .payInfoTotalNum {
            font-size: 18px;
            color: #ff5852;
            font-weight: bold;
          }
        }
      }
      .newBottom {
        padding-bottom: 20px !important;
      }
    }
    .touchBox {
      background-color: #ffffff;
      padding: 26px 0;
      .touchItme {
        .touchItmeBt {
          border: none;
          border: 0.02rem solid #25b9ff;
          color: #25b9ff;
          text-align: center;
          width: 284px;
          background-color: #ffffff;
          padding: 16px 0;
          border-radius: 38px;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
