<template>
  <div class="receivingInfo" v-if="detailsData">
    <div class="box">
      <van-row type="flex" align="center" class="user">
        <van-col class="userTitle">{{ $t.consignee }}</van-col>
        <van-col class="userName">{{ detailsData.ReceivingName }}</van-col>
        <van-col class="userPhone">{{ detailsData.PhoneNumber }}</van-col>
      </van-row>
      <van-row type="flex" align="center" class="address">
        <van-col class="addressTitle">{{ $t.shippingAddress }}</van-col>
        <van-col class="addressInfo">{{
          detailsData.ReceivingAddress
        }}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "ReceivingInfo",
  props: {
    detailsData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.receivingInfo {
  width: 100%;
  .box {
    font-size: 14px;
    margin: 0 auto;
    width: 92%;
    margin-top: 15px;
    padding: 15px;
    background-color: #ffffff;
    @include publicBoxRadio;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    color: #000000;
    .user {
      width: 100%;
      .userTitle {
        width: 25%;
        color: #a7a7a7;
      }
      .userName {
        width: 30%;
      }
      .userPhone {
        width: 45%;
      }
    }
    .address {
      width: 100%;
      margin-top: 10px;
      .addressTitle {
        width: 25%;
        color: #a7a7a7;
      }
      .addressInfo {
        width: 75%;
      }
    }
  }
}
</style>
