<template>
  <div class="payBottom" v-if="detailsData">
    <van-row type="flex" align="center" class="box">
      <van-col class="boxTitle">{{ $t.payment }}</van-col>
      <van-col class="boxTotal"
        >{{ $t.totalItems(detailsData.ProductCount) }}
        {{ detailsData.totalPrice }}:</van-col
      >
      <van-col class="boxCarriage"
        >({{ $t.freight }}${{ detailsData.Freight }})</van-col
      >
      <van-col class="boxNum"
        ><span class="boxUnit">$</span>&nbsp;{{ detailsData.AllPrice }}</van-col
      >
    </van-row>
    <!-- <div class="btBox">
      <button class="bt">{{ $t.remindSupplierOfShipment }}</button>
    </div> -->
  </div>
</template>
<script>
import Vue from "vue";
import { Row, Col } from "vant";
Vue.use(Row).use(Col);
export default {
  name: "PayBottom",
  props: {
    detailsData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.payBottom {
  width: 100%;
  font-size: 14px;
  margin-top: 30px;
  background-color: #ffffff;
  .box {
    width: 92%;
    margin: 0 auto;
    padding: 20px 0;
    .boxTitle {
      color: #000000;
      width: 15%;
    }
    .boxTotal {
      width: 32%;
      color: #999999;
    }
    .boxCarriage {
      width: 30%;
      color: #999999;
    }
    .boxNum {
      width: 23%;
      text-align: left;
      color: #ff5852;
      font-size: 18px;
      font-weight: bold;
      @include spanNowrap;
      .boxUnit {
        font-size: 18px;
        margin-left: 4px;
      }
    }
  }
  .btBox {
    width: 92%;
    margin: 0 auto;
    text-align: right;
    padding-bottom: 30px;
    .bt {
      border: none;
      background-color: #ffffff;
      border-radius: 40px;
      padding: 22px 0;
      border: 0.025rem solid #43cd9a;
      color: #43cd9a;
      width: 47%;
    }
  }
}
</style>
